/* eslint-env jest */
import mutations from './mutations';

jest.mock('@/models/Encoder', () => {
  const v = function v(o) {
    Object.entries(o).forEach(([key, value]) => {
      this[key] = value;
    });
  };
  return v;
});

describe('mutations', () => {
  it('setEncoder should set encoder data to state', () => {
    const config1 = {
      options: {
        name: 'test1',
      }
    };
    const state = {};

    mutations.setEncoder(state, {
      1: config1,
    });
    expect(state).toEqual({
      1: config1,
    });
  });

  it('setEncoderByProps should set encoder data to state by props of websocket', () => {
    const config1 = {
      options: {
        name: 'test1',
      }
    };
    const state = { 1: config1 };

    mutations.setEncoderByProps(state, {
      1: {
        name: 'test2'
      },
    });
    expect(state).toEqual({
      1: {
        options: {
          name: 'test2',
        }
      }
    });
  });

  it('setRecordingStatus should set recording data to state', () => {
    const config1 = {
      manual: 'ON',
    };
    const state = { 1: { recording_status: {} } };

    mutations.setRecordingStatus(state, {
      1: config1,
    });
    expect(state).toEqual({
      1: {
        recording_status: config1,
      }
    });
  });

  it('setRecordingStatusByProps should set recording data to state by props of websocket', () => {
    const config1 = {
      recording_status: {
        manual: 'ON',
      }
    };
    const state = { 1: config1 };

    mutations.setRecordingStatusByProps(state, {
      1: {
        Manual: false,
        State: 'Recording'
      },

    });
    expect(state).toEqual({
      1: {
        recording_status: {
          manual: 'OFF',
          state: 'ON'
        }
      }
    });
  });

  it('writeSettingDirty should save original data to dirtyTable', () => {
    const config0 = {
      key1: {
        CurrentOpt: 'abc',
      },
      key2: {
        CurrentOpt: 123,
      },
    };
    const config1 = {
      key1: 'efg',
      key2: 456,
      index: 1,
    };
    const state = {
      dirtyTable: {},
      setting: {
        1: {
          ...config0,
        },
      },
    };

    mutations.writeSettingDirty(state, config1);
    expect(state).toEqual({
      dirtyTable: {
        1: {
          key1: 'abc',
          key2: 123,
        }
      },
      setting: {
        1: {
          key1: {
            CurrentOpt: 'efg',
          },
          key2: {
            CurrentOpt: 456,
          },
        },
      }
    });
  });

  it('writeSettingDirty should not save original data to dirtyTable if dirtyTable had original data', () => {
    const config0 = {
      key1: {
        CurrentOpt: 'zzz',
      },
      key2: {
        CurrentOpt: 123,
      },
    };
    const config1 = {
      key1: 'efg',
      key2: 456,
      index: 1,
    };
    const state = {
      dirtyTable: {
        1: {
          key1: 'zzz',
          key2: 123,
        },
      },
      setting: {
        1: {
          ...config0,
        },
      },
    };

    mutations.writeSettingDirty(state, config1);

    expect(state.dirtyTable).toEqual({
      1: {
        key1: 'zzz', // would not be overwritten
        key2: 123,
      },
    });
  });

  it('setDIDOStatus should set dido_status to state', () => {
    const didoStatus = {
      0: {
        do: [0, 0],
        di: [1, 1]
      }
    };

    const state = { 0: { name: 'test' } };

    mutations.setDIDOStatus(state, didoStatus);
    expect(state).toEqual({
      0: {
        name: 'test',
        do: [0, 0],
        di: [1, 1]
      }
    });
  });

  it('setDIDOStatusByProps should set dido_status to state by props of websocket', () => {
    const didoStatus = {
      0: {
        DO: [0, 0],
        DI: [1, 1]
      }
    };

    const state = { 0: { name: 'test', do: [1, 1], di: [0, 0] } };

    mutations.setDIDOStatusByProps(state, didoStatus);
    expect(state).toEqual({
      0: {
        name: 'test',
        do: [0, 0],
        di: [1, 1]
      }
    });
  });

  it('manualRecodingStart should set manual to ON', () => {
    const config1 = {
      recording_status: {
        manual: 'OFF',
      }
    };
    const state = { 1: config1 };

    mutations.manualRecodingStart(state, 1);
    expect(state).toEqual({
      1: {
        recording_status: {
          manual: 'ON',
        }
      }
    });
  });

  it('manualRecodingStop should set manual to OFF', () => {
    const config1 = {
      recording_status: {
        manual: 'ON',
      }
    };
    const state = { 1: config1 };

    mutations.manualRecodingStop(state, 1);
    expect(state).toEqual({
      1: {
        recording_status: {
          manual: 'OFF',
        }
      }
    });
  });
});
